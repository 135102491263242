<template>
  <div class="services">
    <div class="services-container">
      <div
        v-if="props.title"
        class="services-title text-xl-1"
        v-html="props.title"
      />
      <div class="services-list">
        <ul>
          <li v-for="(accordion, index) in services" :key="accordion.id">
            <WidgetAccordion
              ref="accordion"
              :degrees-rotation="180"
              :isOpen="openIndex === index"
              :indexEl="index"
              @toggle="handleToggle"
            >
              <template #header>
                <div
                  class="services-accordion-header flex is-align-center is-justify-space-between"
                >
                  <div
                    class="services-accordion-title text-l-2"
                    v-html="accordion.title"
                  />
                  <div class="accordion__button__icon">
                    <img
                      src="@/assets/img/ico/ico-accordion-arrow-down-blue.svg"
                      width="56.6"
                      height="56.6"
                      :alt="'services'"
                    />
                  </div>
                </div>
              </template>
              <template #content>
                <div
                  class="services-accordion-content text-xl-2"
                  v-html="accordion.text"
                />
              </template>
            </WidgetAccordion>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesTechnologyServices',
}
</script>

<script setup>
const props = defineProps({
  services: {
    type: Array,
    default: () => [],
  },
  title: String,
})

const openIndex = ref(null)

const handleToggle = (index) => {
  openIndex.value = openIndex.value === index ? null : index
}

onMounted(() => {
  openIndex.value = 0
})
</script>

<style lang="scss">
@import './style.scss';
</style>

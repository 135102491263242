<template>
  <div
    :class="{
      '--expanded': isOpen,
    }"
    class="accordion"
  >
    <div ref="header" class="accordion__header">
      <button
        class="accordion__button"
        :aria-pressed="isOpen"
        :class="{
          '--expanded': isOpen,
        }"
        @click="clickedEl"
      >
        <slot name="header" />
      </button>
    </div>
    <div ref="content" class="accordion__content">
      <div ref="wrap" class="accordion__content__wrap">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetAccordion',
}
</script>

<script setup>
import { gsap, Expo } from 'gsap'

const ease = Expo.easeOut
/** TODO: Togliere GSAP e mettere animazione in css (innerHeight può esser passata con CSS VAR) */
const animationOpen = (el, innerHeight, icon, degrees) => {
  gsap.fromTo(el, { height: 0 }, { height: innerHeight, duration: 0.4, ease })
  gsap.fromTo(
    icon,
    { rotation: 0, transformOrigin: '50% 50%' },
    { rotation: degrees, duration: 0.4, ease }
  )
}

const animationClose = (el, innerHeight, icon, degrees) => {
  gsap.fromTo(el, { height: innerHeight }, { height: 0, duration: 0.4, ease })
  gsap.fromTo(
    icon,
    { rotation: degrees, transformOrigin: '50% 50%' },
    { rotation: 0, duration: 0.4, ease }
  )
}

const emit = defineEmits(['enter', 'open', 'leave', 'close', 'toggle'])

const props = defineProps({
  limit: {
    type: Number,
    default: 400,
  },
  degreesRotation: {
    type: Number,
    default: 180,
  },
  heightOffset: {
    type: Number,
    default: 0,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  indexEl: Number,
})

const header = ref(null)
const content = ref(null)
const wrap = ref(null)
const height = ref(0)

const clickedEl = () => {
  emit('toggle', props.indexEl)
}

watch(
  () => props.isOpen,
  async (val) => {
    const icon = header.value.querySelector('.accordion__button__icon')
    if (val) {
      emit('enter')
      height.value =
        wrap.value.getBoundingClientRect().height + props.heightOffset
      await animationOpen(
        content.value,
        height.value,
        icon,
        props.degreesRotation
      )
      emit('open')
    } else {
      emit('leave')
      await animationClose(
        content.value,
        height.value,
        icon,
        props.degreesRotation
      )
      emit('close')
    }
  }
)
</script>

<style lang="scss">
@import './style.scss';
</style>
